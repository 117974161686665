import React from "react";
export default function Datzpress(props: {
  color?: string;
  className?: string;
}) {
  const { color = "#404041", className } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 81.98 56"
      width={82}
      height={56}
      fill={color}
      className={className}
    >
      <path d="M39.57,33.06c.34-.19,1-.48,1.43-.72a1.64,1.64,0,0,1,.61-.21c.08,0,.25.24.25.3l0,1c0,.07.15.15.21.07a3.36,3.36,0,0,1,2.27-1.24c.61,0,1.17.42,1.17.65a2.16,2.16,0,0,1-.84,1.28.61.61,0,0,1-.34.17.84.84,0,0,1-.48-.21,1.3,1.3,0,0,0-.76-.25,1.09,1.09,0,0,0-1,.48,1,1,0,0,0-.19.48c0,.21,0,3.24,0,4,.06,1,.23,1.2,1.17,1.45.48.12.75.29.75.48s-.27.36-.52.36c-.65,0-1.73-.09-2.34-.09s-1.11.07-1.7.07c-.37,0-.56-.24-.56-.34s.23-.36.48-.44c.82-.29.88-.61.9-1.09,0-1,.07-3.53.07-4.64,0-.13,0-.28-.53-.63-.23-.17-.47-.36-.47-.49A.53.53,0,0,1,39.57,33.06Z" />
      <path d="M52.8,33.54A2.71,2.71,0,0,1,53.15,35a.8.8,0,0,1-.57.63l-4.51.07a.52.52,0,0,0-.33.23,1.81,1.81,0,0,0-.11.65,3.21,3.21,0,0,0,3.11,3.3h.1a4.3,4.3,0,0,0,2.12-.8c.09-.06.26,0,.26.27a1,1,0,0,1-.28.65,4.19,4.19,0,0,1-3,1.3c-2.62,0-4.11-1.78-4.11-4.34a4.47,4.47,0,0,1,4.49-4.69A2.88,2.88,0,0,1,52.8,33.54Zm-4.92,1.2A.23.23,0,0,0,48,35c.23,0,.93,0,1.53,0,.8,0,1.76,0,1.76-.76S50.8,33,49.69,33A2.32,2.32,0,0,0,47.88,34.74Z" />
      <path d="M58.92,32.45l.67.19a1,1,0,0,1,.36.42,8.84,8.84,0,0,1,0,1.15c0,.15-.17.61-.46.61-.09,0-.27-.19-.47-.56A2.09,2.09,0,0,0,57.46,33a1.3,1.3,0,0,0-1.22,1.24c0,1,.91,1.3,2,1.86s2,1.22,2,2.46a2.48,2.48,0,0,1-1.57,2.31,4.31,4.31,0,0,1-1.81.4,3.15,3.15,0,0,1-2.14-.53,3.17,3.17,0,0,1-.42-1.51c0-.54.15-.7.38-.7s.27.15.55.57a2.52,2.52,0,0,0,1.91,1.34,1.34,1.34,0,0,0,1.47-1.3A1.41,1.41,0,0,0,57.86,38c-.57-.34-1-.57-1.66-.94a2.58,2.58,0,0,1-1.51-2.29,2.37,2.37,0,0,1,1.09-2,4.19,4.19,0,0,1,1.83-.55A4.45,4.45,0,0,1,58.92,32.45Z" />
      <path d="M66.05,32.45l.67.19a1.1,1.1,0,0,1,.35.42,11.06,11.06,0,0,1,.05,1.15c0,.15-.17.61-.47.61-.08,0-.27-.19-.47-.56A2.07,2.07,0,0,0,64.59,33a1.28,1.28,0,0,0-1.22,1.24c0,1,.9,1.3,2,1.86s2,1.22,2,2.46a2.51,2.51,0,0,1-1.58,2.31,4.3,4.3,0,0,1-1.8.4,3.15,3.15,0,0,1-2.14-.53,3.06,3.06,0,0,1-.42-1.51c0-.54.14-.7.37-.7s.28.15.55.57a2.52,2.52,0,0,0,1.91,1.34,1.35,1.35,0,0,0,1.47-1.3A1.4,1.4,0,0,0,65,38c-.57-.34-1-.57-1.66-.94a2.59,2.59,0,0,1-1.52-2.29,2.41,2.41,0,0,1,1.07-2,4.23,4.23,0,0,1,1.83-.55A4.38,4.38,0,0,1,66.05,32.45Z" />
      <path d="M36.89,33.19a3.25,3.25,0,0,0-2.27-.89,3.52,3.52,0,0,0-1.28.26,9.63,9.63,0,0,0-1.4.7c-.08,0-.21,0-.21-.11s0-.5,0-.73-.27-.28-.38-.28c-.31,0-1.21.3-2.66.61a.76.76,0,0,0-.47.57c0,.23.17.38.29.35a9.28,9.28,0,0,1,1.17-.12c.17,0,.36.17.38.84,0,.86.06,1.93,0,5.94,0,1.3-.06,2.42-.13,3.66,0,.71-.16,1.11-1.16,1.38-.4.11-.57.3-.57.42,0,.32.36.38.61.38S30.15,46,31,46s1.76,0,1.93,0c.36,0,.59-.17.59-.4s-.15-.27-.63-.42c-1.07-.31-1.13-.55-1.13-.82,0-.71,0-1.68,0-2.62,0-.17.14-.45.21-.42a4.9,4.9,0,0,0,.77,0,7.73,7.73,0,0,0,2.31-.44,4.77,4.77,0,0,0,3.17-4.56A4.42,4.42,0,0,0,36.89,33.19ZM35.4,39.63a2.23,2.23,0,0,1-1.64.67A2.58,2.58,0,0,1,32,39.17c-.13-.19-.19-.47-.19-1.68,0-.86,0-2.44.06-3.13a.74.74,0,0,1,.27-.4,2.93,2.93,0,0,1,1.6-.44c1.85,0,2.69,1.93,2.69,3.5A3.89,3.89,0,0,1,35.4,39.63Z" />
      <path d="M21.24,20.17c.08,0,.16-.11.16-.23s0-2.69,0-3.13c-.06-.7-.15-.76-1.07-1a.53.53,0,0,1-.4-.44c0-.06.09-.17.44-.27q1.28-.31,2.58-.51c.21,0,.27.32.27,1.29,0,1.32-.08,4.2-.08,5.9,0,4.22.06,4.57.08,5.18s.21.84.38.84a9.28,9.28,0,0,0,1.17-.12c.13,0,.29.12.29.35a.77.77,0,0,1-.46.57c-1.46.32-2.36.61-2.68.61-.1,0-.35-.13-.38-.27s0-.65-.06-.86c0-.05-.1-.09-.17-.05a6.33,6.33,0,0,1-2.58,1.05,3.89,3.89,0,0,1-4-4.11A4.58,4.58,0,0,1,17,20.91,6.13,6.13,0,0,1,20,20.07,5.62,5.62,0,0,1,21.24,20.17Zm-3.6,1.38a3.59,3.59,0,0,0-1,2.74c0,1.61,1,3.54,2.9,3.54a3.05,3.05,0,0,0,1.63-.52,1.06,1.06,0,0,0,.21-.53c0-1.36,0-2.58,0-3.94a2.53,2.53,0,0,0-.15-.82A2.58,2.58,0,0,0,19.07,21,2.18,2.18,0,0,0,17.64,21.55Z" />
      <path d="M32.1,20.86a3.13,3.13,0,0,1,.71,1.81c0,.8-.08,1.68-.08,4,0,.7.36,1,.75,1a2.34,2.34,0,0,0,.7-.19c.09,0,.19.17.19.33a2.11,2.11,0,0,1-.19.55,1.86,1.86,0,0,1-1.51.76,1.46,1.46,0,0,1-1.49-1.31s-.17-.12-.21-.1a16.87,16.87,0,0,1-1.62,1,2.16,2.16,0,0,1-1.11.31,2.38,2.38,0,0,1-2-2.48,1.57,1.57,0,0,1,.32-1A1.89,1.89,0,0,1,27.4,25,19.3,19.3,0,0,0,31,24a.41.41,0,0,0,.23-.31v-.8a1.69,1.69,0,0,0-.06-.55,2,2,0,0,0-1.85-1.16,1.63,1.63,0,0,0-.93.27,2.1,2.1,0,0,0-.21,1,.66.66,0,0,1-.19.46,1.47,1.47,0,0,1-.61.17,3.26,3.26,0,0,1-.88-.1.53.53,0,0,1-.25-.34c0-.36.61-1,1.51-1.63a5.07,5.07,0,0,1,2.48-1A2.59,2.59,0,0,1,32.1,20.86ZM30.94,27a2,2,0,0,0,.17-.63l0-1.34c0-.08-.1-.13-.17-.13a21.52,21.52,0,0,0-2.24.55c-.59.21-.78.44-.78,1a1.4,1.4,0,0,0,1.4,1.3A2.6,2.6,0,0,0,30.94,27Z" />
      <path d="M40.87,20.23c.15,0,.26.23.28.36s0,.45,0,.67-.26.27-.49.27l-2.37,0s-.15.09-.15.32l-.06,3.82c0,.93.06,1.28.31,1.58a2,2,0,0,0,1.14.46,3.15,3.15,0,0,0,1-.19,4.05,4.05,0,0,1,.47-.12c.09,0,.19.14.19.31a1,1,0,0,1-.5.74,3.91,3.91,0,0,1-2.15.65,2.34,2.34,0,0,1-1.84-1,2.81,2.81,0,0,1-.29-1.49c0-1.47.1-2.75.1-4.75,0-.23-.06-.25-.65-.33a1,1,0,0,1-.63-.61c0-.08.25-.27.44-.44a10.13,10.13,0,0,0,1.5-1.25,1.36,1.36,0,0,1,.84-.54c.08,0,.19.21.19.31s0,.9,0,1,0,.25.17.25S40.39,20.23,40.87,20.23Z" />
      <path d="M49.21,20.23a.29.29,0,0,1,.21.09.44.44,0,0,1,.12.35l-1.13,1.66c-.78,1.17-2.65,4.26-3.15,5.21,0,0,0,.19,0,.23a4,4,0,0,0,2.14.32c.28,0,.91-.21,1.85-1.22.29-.32.54-.55.78-.55s.25.32.18.51c-.14.4-.35,1.16-.56,1.7a.88.88,0,0,1-.36.38c-1.61,0-2.06-.07-3.67-.07-1,0-2.36.11-2.61.11s-.35-.3-.35-.42a5.62,5.62,0,0,1,.75-1.24c.44-.63,2.69-4.28,3.59-5.88a.15.15,0,0,0,0-.2h0a8.55,8.55,0,0,0-2.31-.11c-.52,0-.86.34-1.47,1.07a1,1,0,0,1-.47.36c-.16,0-.27-.21-.27-.36a4.31,4.31,0,0,1,.32-1.07,6.09,6.09,0,0,0,.25-.86.62.62,0,0,1,.31-.38.2.2,0,0,1,.21,0,1.43,1.43,0,0,0,1.05.38c.24,0,1.17.09,2.36.07C47.91,20.29,48.93,20.23,49.21,20.23Z" />
    </svg>
  );
}
