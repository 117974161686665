import React from "react";
export default function DatzBooks(props: {
  color?: string;
  className?: string;
}) {
  const { color = "#404041", className } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92.77 56"
      width={93}
      height={56}
      fill={color}
      className={className}
    >
      <path d="M36.4,35.92l-.57.29a.13.13,0,0,0-.09.18.16.16,0,0,0,.09.09,2.94,2.94,0,0,1,1.16.67,2.24,2.24,0,0,1,.72,1.64,2.78,2.78,0,0,1-1.29,2.29,5.36,5.36,0,0,1-2.85.64c-1.23,0-1.95-.11-2.28-.11-.53,0-1,.07-1.66.07-.43,0-.65-.17-.65-.37s.41-.35.65-.41c.84-.21.94-.49,1-.7a25.41,25.41,0,0,0,.16-3.49,26.79,26.79,0,0,0-.12-2.83c-.08-.55-.17-.65-1.13-.84C29.2,33,29,32.9,29,32.68s.2-.33.57-.33H30c.74,0,1.85-.12,3.75-.12a4.17,4.17,0,0,1,2.36.53,2.12,2.12,0,0,1,1,1.63A1.71,1.71,0,0,1,36.4,35.92Zm-3.87-2.8a.58.58,0,0,0-.15.43c0,.58,0,1.52,0,2.46,0,.15.24.31.37.31a6,6,0,0,0,1.83-.27,1.27,1.27,0,0,0,.71-1.27,1.68,1.68,0,0,0-.51-1.33A2.53,2.53,0,0,0,33.16,33a1.23,1.23,0,0,0-.63.13Zm2.6,4.44A2.69,2.69,0,0,0,33.24,37c-.45,0-.8,0-.82.29v2.44a1.11,1.11,0,0,0,.37.9,2.37,2.37,0,0,0,1,.37,2.47,2.47,0,0,0,1.12-.39,2,2,0,0,0,.9-1.56,1.86,1.86,0,0,0-.71-1.52Z" />
      <path d="M49,37a4.77,4.77,0,0,1-1.83,3.81A5.71,5.71,0,0,1,44,41.93a5.39,5.39,0,0,1-3.82-1.54A4.86,4.86,0,0,1,38.88,37a4.7,4.7,0,0,1,2-3.91,5,5,0,0,1,3.09-1A4.89,4.89,0,0,1,49,37ZM41.79,33.7a4.56,4.56,0,0,0-.88,3.13,5.29,5.29,0,0,0,1,3.12,3.12,3.12,0,0,0,2.3,1.13,2.46,2.46,0,0,0,1.66-.6,4.62,4.62,0,0,0,1-3.36c0-1.88-.91-4.26-3.34-4.26a2.71,2.71,0,0,0-1.81.83Z" />
      <path d="M59.91,37a4.77,4.77,0,0,1-1.82,3.81,5.77,5.77,0,0,1-3.16,1.15,5.37,5.37,0,0,1-3.81-1.54A4.86,4.86,0,0,1,49.83,37a4.72,4.72,0,0,1,2-3.91,5,5,0,0,1,3.1-1A4.88,4.88,0,0,1,59.91,37ZM52.74,33.7a4.5,4.5,0,0,0-.88,3.13,5.23,5.23,0,0,0,1,3.12,3.1,3.1,0,0,0,2.3,1.13,2.48,2.48,0,0,0,1.66-.6,4.67,4.67,0,0,0,1-3.36c0-1.88-.9-4.26-3.34-4.26a2.68,2.68,0,0,0-1.8.83Z" />
      <path d="M68.11,41.62c-.31,0-1,0-1.17,0-.36,0-.55-.16-.55-.29s.14-.32.37-.43c.47-.23.41-.37.23-.57-.71-.84-2-2.38-2.91-3.3-.23-.29-.37-.17-.37-.08,0,.45,0,2.33,0,2.83,0,.7.17.84.94,1.08.23.06.53.21.53.39s-.31.39-.55.39-1.05-.06-1.87-.06c-.53,0-1.29.06-1.62.06s-.63-.16-.63-.33.23-.34.57-.45c.82-.24.92-.39,1-1.08.06-1,.09-5.14.09-5.76s-.24-.78-.94-1c-.37-.12-.55-.24-.55-.43s.23-.33.61-.33,1,.08,1.66.08,1.46-.08,1.75-.08.55.11.55.33-.33.35-.59.45c-.56.17-.74.39-.76.72,0,.63-.11,1.27-.11,2.19,0,.25.09.37.15.37s.47-.18.93-.59a29.83,29.83,0,0,0,2.38-2.25c.16-.16.08-.31-.37-.43a.48.48,0,0,1-.38-.44c0-.18.3-.29.46-.29l.8,0c.94,0,1.83-.12,2.5-.12.37,0,.58.23.58.39s-.39.33-.66.37A5,5,0,0,0,68,34.06a17.84,17.84,0,0,0-2.38,2c-.06,0,0,.19,0,.24.54.65,1.87,2.27,2.75,3.2a4,4,0,0,0,2.27,1.47c.35.1.56.33.56.49s-.25.29-.62.29S68.83,41.62,68.11,41.62Z" />
      <path d="M77.48,32.44a.63.63,0,0,1,.23.43c0,.45.14,1.06.12,1.49,0,.24-.12.56-.35.56s-.34-.29-.43-.51a3,3,0,0,0-.61-1A2,2,0,0,0,75,32.83a1.43,1.43,0,0,0-1.51,1.34v.07A1.83,1.83,0,0,0,74.7,35.8c.67.37,1.23.61,1.86,1a2.74,2.74,0,0,1,1.58,2.34,2.54,2.54,0,0,1-1.08,2.08,4.46,4.46,0,0,1-2.53.72,6.85,6.85,0,0,1-2.37-.43.65.65,0,0,1-.39-.39c-.06-.33-.27-1.63-.27-1.82s.12-.47.35-.47.35.24.93,1.17a2.44,2.44,0,0,0,1.87,1.19,1.55,1.55,0,0,0,1.74-1.5c0-.57-.26-1-1-1.46l-1.91-1.06a2.83,2.83,0,0,1-1.52-2.42,2.38,2.38,0,0,1,1.34-2.17,4,4,0,0,1,2-.5A8.75,8.75,0,0,1,77.48,32.44Z" />
      <path d="M21.14,20.17c.08,0,.17-.11.17-.23s0-2.69,0-3.13c-.07-.7-.15-.76-1.08-1a.55.55,0,0,1-.4-.44c0-.06.09-.17.45-.27.85-.21,1.71-.38,2.57-.51.21,0,.28.32.28,1.29,0,1.32-.09,4.2-.09,5.9,0,4.22.07,4.57.09,5.18s.21.84.38.84a8.94,8.94,0,0,0,1.16-.12c.13,0,.3.12.3.35a.78.78,0,0,1-.47.57c-1.45.32-2.36.61-2.67.61-.11,0-.36-.13-.38-.27s0-.65-.07-.86c0-.05-.1-.09-.16-.05a6.36,6.36,0,0,1-2.59,1.05,3.89,3.89,0,0,1-4-4.11,4.59,4.59,0,0,1,2.34-4.07,6.13,6.13,0,0,1,2.92-.84A5.49,5.49,0,0,1,21.14,20.17Zm-3.6,1.38a3.62,3.62,0,0,0-1,2.74c0,1.61,1,3.54,2.89,3.54a3.12,3.12,0,0,0,1.64-.52,1.28,1.28,0,0,0,.21-.53c0-1.36,0-2.58,0-3.94a2.54,2.54,0,0,0-.16-.82A2.55,2.55,0,0,0,19,21,2.18,2.18,0,0,0,17.54,21.55Z" />
      <path d="M32,20.86a3.14,3.14,0,0,1,.72,1.81c0,.8-.09,1.68-.09,4,0,.7.36,1,.76,1a2.42,2.42,0,0,0,.7-.19c.08,0,.19.17.19.33a2.55,2.55,0,0,1-.19.55,1.89,1.89,0,0,1-1.51.76,1.47,1.47,0,0,1-1.5-1.31s-.16-.12-.21-.1a15.57,15.57,0,0,1-1.61,1,2.22,2.22,0,0,1-1.12.31,2.37,2.37,0,0,1-2-2.48,1.56,1.56,0,0,1,.31-1A1.89,1.89,0,0,1,27.3,25a19.3,19.3,0,0,0,3.57-1,.39.39,0,0,0,.23-.31v-.8a1.28,1.28,0,0,0-.06-.55,2,2,0,0,0-1.85-1.16,1.63,1.63,0,0,0-.93.27,2.24,2.24,0,0,0-.21,1,.66.66,0,0,1-.19.46,1.47,1.47,0,0,1-.61.17,3.26,3.26,0,0,1-.88-.1.61.61,0,0,1-.25-.34c0-.36.61-1,1.51-1.63a5.1,5.1,0,0,1,2.48-1A2.57,2.57,0,0,1,32,20.86ZM30.85,27a2.24,2.24,0,0,0,.16-.63L31,25c0-.08-.1-.13-.16-.13a21.7,21.7,0,0,0-2.25.55c-.59.21-.78.44-.78,1a1.41,1.41,0,0,0,1.4,1.3A2.65,2.65,0,0,0,30.85,27Z" />
      <path d="M40.78,20.23c.14,0,.25.23.27.36a5,5,0,0,1,0,.67c-.06.23-.25.27-.48.27l-2.38,0s-.15.09-.15.32L38,25.69c0,.93.06,1.28.32,1.58a1.93,1.93,0,0,0,1.13.46,3.21,3.21,0,0,0,1-.19,3.9,3.9,0,0,1,.46-.12c.09,0,.19.14.19.31a1,1,0,0,1-.5.74,3.87,3.87,0,0,1-2.14.65,2.34,2.34,0,0,1-1.85-1,2.81,2.81,0,0,1-.29-1.49c0-1.47.1-2.75.1-4.75,0-.23-.06-.25-.65-.33a1,1,0,0,1-.63-.61c0-.08.25-.27.44-.44A10.13,10.13,0,0,0,37,19.27a1.36,1.36,0,0,1,.84-.54c.08,0,.19.21.19.31s0,.9,0,1,0,.25.17.25S40.29,20.23,40.78,20.23Z" />
      <path d="M49.11,20.23a.27.27,0,0,1,.21.09.45.45,0,0,1,.13.35l-1.14,1.66c-.77,1.17-2.64,4.26-3.15,5.21,0,0,0,.19,0,.23a4,4,0,0,0,2.15.32c.27,0,.9-.21,1.84-1.22.3-.32.55-.55.78-.55s.25.32.19.51c-.15.4-.36,1.16-.57,1.7a.86.86,0,0,1-.35.38c-1.62,0-2.06-.07-3.68-.07-1,0-2.35.11-2.6.11s-.36-.3-.36-.42a6.08,6.08,0,0,1,.75-1.24c.45-.63,2.69-4.28,3.6-5.88a.14.14,0,0,0,0-.2h0a8.55,8.55,0,0,0-2.31-.11c-.52,0-.86.34-1.47,1.07a1,1,0,0,1-.46.36c-.17,0-.27-.21-.27-.36a4.26,4.26,0,0,1,.31-1.07,5,5,0,0,0,.25-.86.63.63,0,0,1,.32-.38c.08,0,.12,0,.21,0a1.4,1.4,0,0,0,1,.38c.23,0,1.16.09,2.35.07C47.81,20.29,48.84,20.23,49.11,20.23Z" />
    </svg>
  );
}
