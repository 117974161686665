import React from "react";
export default function Darkroom(props: {
  color?: string;
  className?: string;
}) {
  const { color = "#404041", className } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.53 56"
      width={90}
      height={56}
      fill={color}
      className={className}
    >
      <path d="M18.6,29.09A6.47,6.47,0,0,0,21.18,28c.06,0,.16,0,.16,0,0,.21,0,.63.07.86s.27.27.38.27c.31,0,1.21-.29,2.66-.61a.76.76,0,0,0,.47-.56c0-.23-.17-.38-.29-.36a9.3,9.3,0,0,1-1.17.13c-.17,0-.36-.17-.38-.84S23,26,23,21.78c0-1.7.08-4.58.08-5.9,0-1-.06-1.28-.27-1.28a25.18,25.18,0,0,0-2.59.5c-.36.11-.44.21-.44.28a.55.55,0,0,0,.4.44c.93.23,1,.29,1.07,1,0,.44,0,3,0,3.13s-.08.25-.17.23a6.24,6.24,0,0,0-4.19.74A4.59,4.59,0,0,0,14.6,25,3.9,3.9,0,0,0,18.6,29.09Zm-1.06-7.54A2.14,2.14,0,0,1,19,21,2.56,2.56,0,0,1,21.14,22a2.55,2.55,0,0,1,.17.82c0,1.37,0,2.58,0,4a1.1,1.1,0,0,1-.22.52,3.11,3.11,0,0,1-1.63.53c-1.87,0-2.9-1.94-2.9-3.55A3.6,3.6,0,0,1,17.54,21.55Z" />
      <path d="M34.53,19.6a8.61,8.61,0,0,0,.57,1.71c.44,1.24,1.81,4.6,2.21,5.63a2.08,2.08,0,0,0,1.54,1.5c.4.15.51.23.51.36s-.21.4-.66.4c-.63,0-1.3-.11-2-.11s-1.1.05-1.56.05-.53-.17-.53-.32.15-.32.47-.42c.51-.19.59-.28.53-.66s-.51-1.43-.57-1.56a.5.5,0,0,0-.39-.28c-.23,0-.8,0-1.34,0a13.85,13.85,0,0,0-1.44,0,.55.55,0,0,0-.34.24,6.72,6.72,0,0,0-.5,1.45c-.11.55.06.59.5.74s.53.21.53.47-.25.31-.61.31c-.17,0-.34,0-1,0s-1.25.11-1.84.11c-.29,0-.63-.09-.63-.32s.21-.32.53-.42A2.17,2.17,0,0,0,30,27.17c.7-1.35,1.64-3.86,2.6-6a5.79,5.79,0,0,0,.27-.8,2.47,2.47,0,0,1,.36-.49l.53-.42a1.09,1.09,0,0,1,.42-.2A.39.39,0,0,1,34.53,19.6Zm-1.25,2.1c-.3.65-.89,2.17-1.14,3,0,0,.14.23.16.23a7.53,7.53,0,0,0,1.06,0,8.09,8.09,0,0,0,1.1,0s.12-.15.1-.24c-.06-.31-1-2.76-1.07-3S33.3,21.66,33.28,21.7Z" />
      <path d="M46.19,27.9c-.37-.54-1.14-1.94-1.47-2.51a1.53,1.53,0,0,0-1.46-.67A.34.34,0,0,0,43,25a17.27,17.27,0,0,0,.08,2.49c.07.57.36.67,1.14.93.38.13.49.36.49.53s-.19.27-.64.27-1.5-.1-2.21-.1-1,.06-1.5.06-.63-.27-.63-.42.12-.25.57-.4c.8-.3.84-.47.88-.91s.09-2.05.09-3.73a23.65,23.65,0,0,0-.05-2.62c0-.34-.14-.51-1-.74-.25-.06-.59-.25-.59-.53s.28-.27.57-.27l1.29,0c.82,0,1.54-.11,2.55-.11a4.54,4.54,0,0,1,3,.78,2.23,2.23,0,0,1,.79,1.8A1.93,1.93,0,0,1,47,23.71c-.1.08-.59.33-.76.42s0,.17,0,.21a16.77,16.77,0,0,0,1.77,3,3.61,3.61,0,0,0,1.87,1.16c.3.09.4.24.4.32s-.12.25-.37.34a4.23,4.23,0,0,1-1.06.1A3,3,0,0,1,46.19,27.9Zm-3.11-7.49a.66.66,0,0,0-.13.4,21.11,21.11,0,0,0,.08,3s.15.23.26.23a5.41,5.41,0,0,0,2.1-.4A1.62,1.62,0,0,0,46,22.25a2.14,2.14,0,0,0-2.28-2.1A1.46,1.46,0,0,0,43.08,20.41Z" />
      <path d="M57.86,29.08c-.31,0-1,.05-1.2.05-.38,0-.57-.17-.57-.3s.15-.34.38-.44c.49-.23.42-.38.25-.59-.74-.87-2.1-2.45-3-3.4-.26-.29-.38-.17-.38-.08,0,.46-.05,2.4-.05,2.91,0,.72.17.86,1,1.12.26.06.55.21.55.4s-.31.4-.57.4-1.07-.07-1.92-.07c-.55,0-1.32.07-1.66.07S50,29,50,28.81s.24-.36.59-.47c.85-.25,1-.4,1-1.12.06-1,.08-5.29.08-5.92s-.23-.8-.95-1c-.38-.12-.57-.25-.57-.44s.23-.34.63-.34,1,.09,1.71.09,1.5-.09,1.8-.09.57.11.57.34-.34.36-.62.47c-.57.17-.76.4-.78.74,0,.65-.1,1.3-.1,2.25,0,.25.08.38.14.38.22,0,.49-.19,1-.61a27.12,27.12,0,0,0,2.43-2.32c.17-.17.09-.32-.38-.44a.52.52,0,0,1-.4-.45c0-.19.32-.29.49-.29l.82,0c1,0,1.88-.13,2.57-.13.38,0,.59.24.59.4s-.4.34-.68.38a5.21,5.21,0,0,0-2.19,1.08,19,19,0,0,0-2.44,2c-.07.05,0,.19,0,.26.55.67,1.92,2.33,2.82,3.29a4.22,4.22,0,0,0,2.34,1.52c.36.1.57.33.57.51s-.26.29-.63.29S58.6,29.08,57.86,29.08Z" />
      <path d="M35.84,40.36c-.37-.55-1.13-1.93-1.46-2.49a1.5,1.5,0,0,0-1.44-.67.37.37,0,0,0-.3.25,17,17,0,0,0,.09,2.47c.06.56.35.67,1.13.92.37.12.48.35.48.52s-.19.27-.63.27-1.48-.1-2.19-.1-1,.06-1.49.06-.63-.27-.63-.42.13-.25.57-.39c.79-.3.83-.47.88-.9s.09-2,.09-3.71c0-1.94,0-2.13,0-2.59s-.15-.5-1-.73c-.26-.07-.59-.25-.59-.53s.27-.27.57-.27l1.27,0c.82,0,1.53-.1,2.53-.1a4.52,4.52,0,0,1,3,.77,2.2,2.2,0,0,1,.77,1.78,1.93,1.93,0,0,1-.82,1.72q-.36.22-.75.42c-.06,0,0,.16,0,.2a15.55,15.55,0,0,0,1.75,3,3.51,3.51,0,0,0,1.84,1.15c.3.09.4.24.4.32s-.13.25-.38.33a4,4,0,0,1-1,.1A3,3,0,0,1,35.84,40.36Zm-3.09-7.42a.67.67,0,0,0-.12.39,20.65,20.65,0,0,0,.08,3s.15.23.26.23A5.47,5.47,0,0,0,35,36.12a1.67,1.67,0,0,0,.62-1.36,2.12,2.12,0,0,0-2.26-2.07A1.57,1.57,0,0,0,32.75,32.94Z" />
      <path d="M49.72,36.78a4.89,4.89,0,0,1-1.87,3.89,5.91,5.91,0,0,1-3.22,1.17,5.5,5.5,0,0,1-3.89-1.57,5,5,0,0,1-1.31-3.45,4.83,4.83,0,0,1,2-4,5.18,5.18,0,0,1,3.15-1A5,5,0,0,1,49.72,36.78ZM42.4,33.44a4.6,4.6,0,0,0-.9,3.2,5.39,5.39,0,0,0,1,3.17A3.11,3.11,0,0,0,44.88,41a2.49,2.49,0,0,0,1.69-.6,4.75,4.75,0,0,0,1.07-3.43c0-1.92-.92-4.35-3.41-4.35A2.77,2.77,0,0,0,42.4,33.44Z" />
      <path d="M61.09,36.78a4.84,4.84,0,0,1-1.87,3.89A5.85,5.85,0,0,1,56,41.84a5.5,5.5,0,0,1-3.89-1.57,5,5,0,0,1-1.31-3.45,4.83,4.83,0,0,1,2-4,5.21,5.21,0,0,1,3.16-1A5,5,0,0,1,61.09,36.78Zm-7.32-3.34a4.6,4.6,0,0,0-.89,3.2,5.34,5.34,0,0,0,1,3.17A3.07,3.07,0,0,0,56.26,41a2.47,2.47,0,0,0,1.69-.6A4.75,4.75,0,0,0,59,36.93c0-1.92-.92-4.35-3.41-4.35A2.81,2.81,0,0,0,53.77,33.44Z" />
      <path d="M62.22,32c.48,0,1.86.08,2.42.08a.54.54,0,0,1,.42.29,13.09,13.09,0,0,0,.63,1.44c.6,1.34,1.73,3.33,2.42,4.69.09.18.19.08.23,0,.3-.4,2-4,2.41-4.9a12.87,12.87,0,0,0,.48-1.22c.06-.14.25-.31.35-.31.51,0,2.07-.1,2.34-.1s.63.18.63.39-.21.32-.67.4c-.84.17-1.07.21-1.07.82,0,1.23.17,5.47.29,6.27.07.44.47.7,1.26.93.5.15.56.27.56.48s-.27.34-.64.34c-.78,0-1.43-.09-2.08-.09s-1.08.07-1.44.07-.65-.15-.65-.38.34-.42.57-.52a1.07,1.07,0,0,0,.61-.57c0-.6,0-4.67-.07-5.33,0-.1-.1-.23-.21-.06-1.79,4-2.07,4.45-2.59,5.85-.23.61-.63,1-.82,1s-.39-.15-.6-.82c-.42-1.4-1.36-3.14-2.47-5.75a.1.1,0,0,0-.12-.08.11.11,0,0,0-.09.1,28.75,28.75,0,0,0-.25,4.44c0,.93.47,1,1.09,1.33.31.19.42.3.42.44s-.13.34-.53.34-.93-.06-1.42-.06-1.67.1-2.1.1c-.25,0-.56-.12-.56-.38s.25-.31.63-.41c.79-.24,1.21-.53,1.35-1.32.17-1,.51-4.37.61-5.54.08-.94-.42-.92-1.23-1.15-.42-.11-.63-.36-.63-.63S62,32,62.22,32Z" />
      <path d="M26.34,19s.05.06.11.06a3.17,3.17,0,0,0,2-2.57,2.45,2.45,0,0,0-1.42-1.93,4.12,4.12,0,0,0-1.27,1.19c0,.08.27.29.45.43s1,.66.93,1.23a2.09,2.09,0,0,1-.59,1.24A.7.7,0,0,0,26.34,19Z" />
    </svg>
  );
}
