export const firebaseConfig = {
  apiKey: "AIzaSyBLWcym-3i-U68oKTFMpLZEVDed0K9fZuw",
  authDomain: "datzpress1.firebaseapp.com",
  databaseURL: "https://datzpress1.firebaseio.com",
  projectId: "datzpress1",
  storageBucket: "datzpress1.appspot.com",
  messagingSenderId: "307709078107",
  appId: "1:307709078107:web:3d56b8eac7f8d1c3c26d7e",
  measurementId: "G-NFGH122L16",
};
