import React from "react";
export default function Cultural(props: {
  color?: string;
  className?: string;
}) {
  const { color = "#404041", className } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.75 56"
      width={114}
      height={56}
      fill={color}
      className={className}
    >
      <path d="M21.09,20.07c.06,0,.17-.13.17-.24s0-2.52,0-3c-.06-.69-.14-.75-1-1-.21-.07-.42-.25-.42-.44s.13-.24.47-.33A23,23,0,0,1,23,14.6c.19,0,.25.36.25,1.28,0,1.31-.1,4.11-.1,5.8,0,4.2.05,4.49.07,5.1s.23.87.41.87a8,8,0,0,0,1.11-.14c.11,0,.27.17.27.38a.75.75,0,0,1-.47.58c-1.42.29-2.48.62-2.8.62-.1,0-.35-.13-.37-.25s0-.65-.06-.86c0,0-.09-.08-.17,0a6.11,6.11,0,0,1-2.57,1,3.9,3.9,0,0,1-3.93-4.13,4.53,4.53,0,0,1,2.34-4A6.37,6.37,0,0,1,19.86,20,7.29,7.29,0,0,1,21.09,20.07Zm-3.41,1.4a3.55,3.55,0,0,0-.94,2.69c0,1.57,1,3.5,2.78,3.5A2.89,2.89,0,0,0,21,27.19a1.19,1.19,0,0,0,.21-.52c0-1.36,0-2.55,0-3.9a2.42,2.42,0,0,0-.17-.84,2.56,2.56,0,0,0-2.05-1,2,2,0,0,0-1.34.55Z" />
      <path d="M32.13,20.85a3.36,3.36,0,0,1,.7,1.78c0,.79-.09,1.6-.09,3.94,0,.7.35,1,.72,1a2.1,2.1,0,0,0,.7-.16c.07,0,.17.17.17.31a2,2,0,0,1-.19.59,2,2,0,0,1-1.56.74A1.5,1.5,0,0,1,31,27.78s-.16-.13-.21-.11a15.42,15.42,0,0,1-1.54,1,2.4,2.4,0,0,1-1.14.31,2.34,2.34,0,0,1-2-2.47,1.61,1.61,0,0,1,.32-1,1.7,1.7,0,0,1,.86-.58,20.65,20.65,0,0,0,3.53-1,.46.46,0,0,0,.23-.33v-.79a1.16,1.16,0,0,0-.06-.52,1.89,1.89,0,0,0-1.75-1.15,1.35,1.35,0,0,0-.9.29,2.86,2.86,0,0,0-.21,1.06.66.66,0,0,1-.21.44,1.25,1.25,0,0,1-.59.19,3.62,3.62,0,0,1-1-.13.54.54,0,0,1-.24-.38c0-.37.64-1,1.54-1.63a5.07,5.07,0,0,1,2.49-1A2.63,2.63,0,0,1,32.13,20.85Zm-1.35,6a1.62,1.62,0,0,0,.16-.61l0-1.33c0-.09-.11-.13-.17-.13a19.45,19.45,0,0,0-2.13.52c-.57.21-.75.43-.75,1a1.35,1.35,0,0,0,1.35,1.25A2.32,2.32,0,0,0,30.78,26.86Z" />
      <path d="M40.83,20.18c.13,0,.25.21.25.34a4.09,4.09,0,0,1,0,.7.44.44,0,0,1-.48.31H38.28s-.14.11-.14.32l-.08,3.73c0,.93.08,1.25.33,1.55a1.82,1.82,0,0,0,1.09.44,3.64,3.64,0,0,0,.93-.17,3.59,3.59,0,0,1,.47-.12c.08,0,.21.14.21.31s-.17.48-.57.79a3.67,3.67,0,0,1-2.13.64,2.36,2.36,0,0,1-1.92-1,2.81,2.81,0,0,1-.28-1.48c0-1.47.11-2.7.11-4.67,0-.24-.06-.25-.62-.36a.94.94,0,0,1-.63-.61c0-.1.23-.29.44-.43a12.23,12.23,0,0,0,1.59-1.31,1.37,1.37,0,0,1,.88-.52c.08,0,.21.21.21.31s0,.93,0,1.06,0,.24.19.24S40.35,20.18,40.83,20.18Z" />
      <path d="M49.21,20.21a.24.24,0,0,1,.21.08.57.57,0,0,1,.1.44c-.16.23-.48.67-1.13,1.63-.8,1.25-2.58,4.17-3.06,5.11,0,0,0,.19,0,.23a3.67,3.67,0,0,0,2,.32c.27,0,.94-.25,1.87-1.25.29-.31.54-.54.77-.54s.27.32.21.55-.38,1.16-.57,1.71a.86.86,0,0,1-.38.35c-1.61,0-2.06-.07-3.67-.07-1,0-2.4.1-2.65.1s-.38-.3-.38-.44a6.3,6.3,0,0,1,.8-1.26c.48-.68,2.64-4.27,3.5-5.77a.13.13,0,0,0,0-.19s0,0,0,0a9.51,9.51,0,0,0-2.17-.13c-.47,0-.86.38-1.47,1.1a1.16,1.16,0,0,1-.46.36.36.36,0,0,1-.31-.35,4.66,4.66,0,0,1,.31-1.08,6.21,6.21,0,0,0,.28-.9.54.54,0,0,1,.29-.38.16.16,0,0,1,.21.05,1.89,1.89,0,0,0,1.09.35c.23,0,1.25.08,2.38.07Z" />
      <path d="M29.17,44.88c0-.7-.08-.83-.68-1-.23-.07-.38-.23-.38-.38s.12-.16.36-.16c.5,0,.81,0,1.32,0,1.33,0,2.67,0,3.26,0,.06,0,.21.09.21.16,0,.32,0,.75,0,1.06s-.25.46-.33.46-.15-.07-.21-.26-.22-.7-.47-.75A10.16,10.16,0,0,0,30.5,44a.26.26,0,0,0-.15.18c0,.47,0,1.13,0,1.85a.23.23,0,0,0,.15.22,3.76,3.76,0,0,0,1.06,0c.29,0,.42-.16.56-.49s.16-.28.24-.28a.31.31,0,0,1,.26.32,14.35,14.35,0,0,0-.09,1.61c0,.29-.15.35-.25.35s-.22-.1-.27-.31c-.11-.39-.2-.49-.39-.52a7,7,0,0,0-1.21-.05s-.07.05-.07.08a13.71,13.71,0,0,0,0,1.57c0,.43.12.57.79.72.16,0,.33.16.33.27s-.18.23-.42.23-.79-.06-1.38-.06c-.38,0-1.06.07-1.25.07s-.42-.07-.42-.24.17-.23.37-.29c.57-.15.76-.29.77-.7s0-.66,0-1C29.19,46.73,29.18,45.2,29.17,44.88Z" />
      <path d="M40.89,46.55a3.22,3.22,0,0,1-1.25,2.58,3.92,3.92,0,0,1-2.14.77,3.63,3.63,0,0,1-2.58-1,3.32,3.32,0,0,1-.87-2.29,3.22,3.22,0,0,1,1.36-2.65,3.51,3.51,0,0,1,2.1-.7,3.32,3.32,0,0,1,3.38,3.24ZM36,44.32a3.09,3.09,0,0,0-.6,2.12,3.53,3.53,0,0,0,.7,2.1,2.09,2.09,0,0,0,1.55.77,1.65,1.65,0,0,0,1.13-.4,3.2,3.2,0,0,0,.71-2.28c0-1.27-.6-2.89-2.25-2.89A1.9,1.9,0,0,0,36,44.32Z" />
      <path d="M41.64,43.36c.25,0,1.12.08,1.43.09.47,0,.9-.07,1.12-.07s.37.1.36.23-.16.25-.44.33-.54.2-.54.67c0,.76,0,1.67,0,2.55a2,2,0,0,0,.63,1.64,1.85,1.85,0,0,0,1.11.37c.93,0,1.61-.25,1.77-1.89.07-.88.1-2.46.09-2.7,0-.46-.18-.5-.7-.65-.31-.1-.39-.21-.39-.32s.2-.21.4-.21.54.06.88.06,1-.08,1.32-.08.34.14.34.23-.19.25-.29.28c-.7.21-.8.43-.82.83s-.07,1.05-.12,1.9a3.65,3.65,0,0,1-.91,2.69,2.67,2.67,0,0,1-1.8.62,2.8,2.8,0,0,1-1.94-.63c-.44-.44-.74-.9-.77-2.68,0-.82,0-2,0-2.25s-.35-.39-.72-.5c-.17,0-.31-.19-.31-.28A.33.33,0,0,1,41.64,43.36Z" />
      <path d="M50.12,43.37c.23,0,1.29.08,1.4.08a1.14,1.14,0,0,1,.47.26c.14.18.79.91,1.07,1.2l2.61,2.81a.13.13,0,0,0,.14-.11h0c0-.4,0-2.38-.1-3s-.4-.53-.82-.67-.36-.21-.36-.32.18-.23.42-.23.91.07,1.29.07.62,0,1,0,.41.07.41.18-.06.26-.39.36c-.63.18-.7.39-.74.62-.08.56-.12,2-.12,2.72s.09,1.67.09,2.28c0,.19-.11.39-.23.39a1,1,0,0,1-.6-.34c-.19-.25-.44-.58-.79-1-.54-.67-2.16-2.42-2.88-3.27-.07,0-.15,0-.15,0,0,1.41,0,2.73.06,3,.06.52.24.66.94.82.19,0,.36.17.36.3s-.16.23-.47.23l-1.5-.08c-.27,0-.51,0-.8,0S50,49.67,50,49.5s.12-.24.38-.31c.53-.15.67-.37.71-.62a10.43,10.43,0,0,0,.12-1.93c0-.7,0-1.46,0-1.83a.52.52,0,0,0-.11-.32,1.5,1.5,0,0,0-.93-.62c-.21,0-.42-.14-.42-.3S49.92,43.37,50.12,43.37Z" />
      <path d="M61.57,49.85c-.82,0-1.82-.06-2.21-.06h-.53c-.22,0-.46-.06-.46-.23s.17-.23.43-.3c.48-.15.65-.34.68-.57s.06-1.15.06-2.58V44.44c0-.25-.27-.34-.65-.44s-.44-.18-.44-.29.21-.24.46-.24l1,0c.21,0,1.32-.08,1.74-.08a4.46,4.46,0,0,1,2.75.77,3,3,0,0,1,1.11,2.36,3.09,3.09,0,0,1-1.67,2.79A5.08,5.08,0,0,1,61.57,49.85ZM60.93,44a.51.51,0,0,0-.21.25c0,.18.06,4.1.06,4.31a.63.63,0,0,0,.33.52,2.18,2.18,0,0,0,.94.2,2.37,2.37,0,0,0,2.18-2.65,2.83,2.83,0,0,0-.64-2,3.25,3.25,0,0,0-2.17-.74,2.09,2.09,0,0,0-.49.08Z" />
      <path d="M69.9,43.53a6.39,6.39,0,0,0,.37,1.12c.29.82,1.19,3,1.45,3.71a1.36,1.36,0,0,0,1,1c.26.1.33.16.33.24s-.14.26-.43.26-.86-.07-1.29-.07l-1,0c-.22,0-.34-.11-.34-.21s.09-.2.3-.27.39-.18.35-.43-.33-1-.37-1a.31.31,0,0,0-.25-.18c-.15,0-.53,0-.89,0a7.38,7.38,0,0,0-.94,0,.34.34,0,0,0-.22.15,4.06,4.06,0,0,0-.34,1c-.07.36,0,.39.33.48s.35.14.35.31-.17.21-.41.21-.22,0-.63,0-.82.06-1.21.06c-.19,0-.42,0-.42-.21s.14-.2.35-.27a1.39,1.39,0,0,0,1-.85c.47-.88,1.09-2.53,1.72-4a4.72,4.72,0,0,0,.18-.53,1.78,1.78,0,0,1,.23-.31l.35-.28a.72.72,0,0,1,.27-.12A.25.25,0,0,1,69.9,43.53Zm-.82,1.38c-.2.43-.59,1.43-.76,2,0,0,.1.15.11.15a4.11,4.11,0,0,0,.7,0,4.45,4.45,0,0,0,.73,0s.08-.1.07-.15c0-.21-.65-1.82-.7-2S69.09,44.89,69.08,44.91Z" />
      <path d="M78.61,45c-.24-.74-.52-.91-.7-.91-.34,0-.79,0-1.21,0,0,0-.1.1-.1.14,0,1.42,0,3.62,0,4.33,0,.39.19.53.78.77.16.05.34.12.34.3s-.23.21-.43.21c-.37,0-.88-.09-1.7-.09-.48,0-.82,0-1.07,0s-.38-.07-.38-.23.12-.24.31-.31c.69-.23.81-.31.84-.62s.13-2,.14-4.14c0-.11-.05-.41-.1-.41s-.88-.06-1.15-.06-.63.57-.8,1-.23.29-.26.29a.32.32,0,0,1-.28-.31c0-.33.14-1.16.19-1.55,0,0,.18-.21.27-.17a3.07,3.07,0,0,0,1.34.24h2.29a4.84,4.84,0,0,0,2-.21c.07,0,.21.14.21.16a9,9,0,0,0,0,1.47.49.49,0,0,1-.29.39C78.77,45.34,78.7,45.29,78.61,45Z" />
      <path d="M81.77,49.81c-.37,0-1.26.06-1.48.06s-.38-.14-.38-.23.13-.22.34-.27c.76-.13.87-.37.89-.8s.07-1.48.07-2c0-1.22,0-1.56,0-1.83s-.1-.52-.76-.67c-.18-.05-.36-.12-.36-.28s.15-.22.39-.22l1.5,0c.27,0,1-.07,1.19-.07s.36.11.36.2-.21.29-.42.34c-.46.13-.72.31-.72.47v1.12c0,1.92,0,2.64.06,3,.11.52.21.64.75.77.23.06.32.17.32.23a.35.35,0,0,1-.39.29C82.62,49.85,82.11,49.81,81.77,49.81Z" />
      <path d="M90.89,46.68a3.23,3.23,0,0,1-1.24,2.57,3.92,3.92,0,0,1-2.14.77A3.67,3.67,0,0,1,84.93,49a3.34,3.34,0,0,1-.87-2.29A3.22,3.22,0,0,1,85.42,44a3.44,3.44,0,0,1,2.1-.69,3.3,3.3,0,0,1,3.37,3.24A.34.34,0,0,1,90.89,46.68ZM86,44.45a3.05,3.05,0,0,0-.6,2.12,3.55,3.55,0,0,0,.69,2.11,2.09,2.09,0,0,0,1.55.77,1.68,1.68,0,0,0,1.13-.4,3.16,3.16,0,0,0,.71-2.28c0-1.27-.6-2.89-2.26-2.89a1.84,1.84,0,0,0-1.22.57Z" />
      <path d="M91.64,43.47c.22,0,1.29.09,1.4.09a1.26,1.26,0,0,1,.47.25c.14.18.79.92,1.06,1.21l2.62,2.8a.12.12,0,0,0,.14-.1h0c0-.4,0-2.39-.11-3s-.39-.53-.81-.67-.36-.21-.36-.32.18-.22.42-.22.91.07,1.29.07.62-.05,1-.05.41.07.4.18,0,.26-.39.36c-.62.18-.7.38-.73.62a25.58,25.58,0,0,0-.12,2.72c0,.56.09,1.67.09,2.28,0,.19-.11.39-.23.39a1,1,0,0,1-.6-.34c-.19-.25-.44-.58-.79-1-.54-.67-2.16-2.42-2.88-3.26-.07-.05-.15-.05-.15,0,0,1.41,0,2.73.06,3.05.06.52.23.65.94.82.19,0,.36.17.36.3s-.16.23-.47.23c-.07,0-1.17-.08-1.5-.08s-.51,0-.8,0-.45-.06-.45-.23.13-.22.39-.31c.53-.15.67-.37.71-.62a9.59,9.59,0,0,0,.13-1.93c0-.7,0-1.46,0-1.83a.52.52,0,0,0-.11-.32,1.55,1.55,0,0,0-.94-.61c-.2-.06-.41-.14-.41-.31S91.43,43.47,91.64,43.47Z" />
      <path d="M33.18,40.33a5.27,5.27,0,0,1-1.93.3,3.72,3.72,0,0,1-2.78-1.08,3.28,3.28,0,0,1-.83-2.29,3.09,3.09,0,0,1,1.2-2.45A4.38,4.38,0,0,1,31.48,34a8.76,8.76,0,0,1,1.9.31.45.45,0,0,1,.18.27,8.94,8.94,0,0,1,.21,1.17c0,.25-.13.37-.27.37s-.21-.14-.29-.32a2.81,2.81,0,0,0-.55-.82,2.24,2.24,0,0,0-1.43-.45,2,2,0,0,0-1.51.7,3.05,3.05,0,0,0-.69,2.1,3,3,0,0,0,.65,1.87,2.79,2.79,0,0,0,1.86.91,2.34,2.34,0,0,0,1.2-.39,3.65,3.65,0,0,0,.58-.83.64.64,0,0,1,.45-.28c.12,0,.15.21.08.39A5.21,5.21,0,0,0,33.54,40C33.53,40.19,33.35,40.25,33.18,40.33Z" />
      <path d="M34.91,34.09c.25,0,1.12.08,1.43.08s.9-.06,1.12-.06.36.09.36.23-.16.25-.44.33c-.43.11-.54.2-.55.67,0,.76,0,1.66,0,2.56a2,2,0,0,0,.64,1.64,1.93,1.93,0,0,0,1.11.38c.93,0,1.61-.26,1.77-1.9.07-.88.1-2.46.09-2.7,0-.46-.18-.49-.7-.65-.31-.1-.39-.21-.39-.32s.19-.21.4-.21.55.06.88.06,1-.08,1.32-.08.34.14.34.23-.19.25-.29.28c-.7.21-.81.43-.82.83s-.07,1.06-.12,1.9a3.68,3.68,0,0,1-.9,2.69,2.66,2.66,0,0,1-1.81.62A2.82,2.82,0,0,1,36.41,40c-.43-.46-.72-.93-.76-2.7,0-.82,0-2,0-2.25s-.34-.39-.72-.5c-.16-.06-.3-.2-.3-.28S34.69,34.09,34.91,34.09Z" />
      <path d="M48.12,40.51c-.39,0-1.47-.06-3.19-.06-.85,0-1.54,0-1.78,0s-.33-.08-.33-.21.19-.25.41-.3c.67-.17.85-.24.88-.68,0-.23,0-.61,0-.94,0-1,0-2.58,0-3.19,0-.35-.27-.42-.63-.53s-.34-.18-.34-.32.12-.18.31-.18.78.05,1.18.05c.61,0,1-.1,1.57-.1.23,0,.39.09.39.21s-.13.27-.45.33c-.61.13-.73.26-.76.6s-.06,2.25-.07,3.19c0,.28,0,.54,0,.88a.7.7,0,0,0,.36.48,6.65,6.65,0,0,0,1.18.12,1,1,0,0,0,.93-.42,5.43,5.43,0,0,0,.63-.88c.14-.2.19-.31.29-.31s.26.25.21.42a15.74,15.74,0,0,0-.51,1.65A.61.61,0,0,1,48.12,40.51Z" />
      <path d="M54.58,35.68c-.25-.74-.52-.91-.7-.91-.35,0-.79,0-1.21,0,0,0-.11.1-.11.14,0,1.42,0,3.62,0,4.33,0,.39.19.53.79.77.15.05.33.12.33.3s-.23.21-.43.21c-.36,0-.87-.08-1.69-.09-.49,0-.82,0-1.07,0s-.39-.07-.39-.23.13-.23.32-.3c.68-.24.81-.32.84-.63s.13-2,.13-4.13c0-.11,0-.42-.09-.42s-.88-.06-1.16-.06-.62.57-.79,1-.23.29-.26.29a.31.31,0,0,1-.28-.3c0-.34.14-1.17.18-1.56,0,0,.18-.21.28-.16a3.19,3.19,0,0,0,1.33.23h2.29a5,5,0,0,0,2-.2c.07,0,.21.14.21.15a9,9,0,0,0,0,1.47.47.47,0,0,1-.3.39C54.73,36,54.67,36,54.58,35.68Z" />
      <path d="M56.33,34.14c.24,0,1.12.09,1.43.09.46,0,.9-.07,1.12-.07s.36.1.36.24-.17.24-.45.33-.54.19-.54.66c0,.77,0,1.67,0,2.56a2,2,0,0,0,.64,1.63A1.93,1.93,0,0,0,60,40c.93,0,1.61-.26,1.76-1.9.08-.88.11-2.46.09-2.69,0-.47-.18-.5-.7-.66-.3-.09-.38-.21-.38-.32s.19-.21.4-.2.54.05.88.05,1-.08,1.31-.08.35.14.35.24-.19.24-.29.27c-.7.21-.81.43-.82.83s-.08,1.06-.12,1.9a3.7,3.7,0,0,1-.91,2.7,2.63,2.63,0,0,1-1.8.61,2.86,2.86,0,0,1-2-.62c-.44-.45-.73-.91-.77-2.69,0-.81,0-2,0-2.25s-.34-.39-.72-.5c-.17,0-.3-.19-.3-.28S56.1,34.14,56.33,34.14Z" />
      <path d="M68.83,39.74c-.25-.36-.75-1.28-1-1.65a1,1,0,0,0-1-.45.24.24,0,0,0-.2.17,12.68,12.68,0,0,0,.05,1.63c0,.38.24.45.75.61.25.09.32.24.32.35s-.13.18-.42.18-1-.07-1.46-.07-.65,0-1,0-.42-.18-.42-.27.08-.17.38-.26c.53-.2.55-.31.58-.6s.06-1.35.06-2.46c0-1.29,0-1.41,0-1.72s-.1-.33-.65-.48c-.17,0-.39-.17-.39-.35s.18-.18.38-.18.46,0,.84,0c.54,0,1-.07,1.68-.07a3,3,0,0,1,2,.52,1.45,1.45,0,0,1,.51,1.16A1.29,1.29,0,0,1,69.37,37c-.16.1-.32.19-.5.28s0,.11,0,.13a11,11,0,0,0,1.17,2,2.29,2.29,0,0,0,1.21.76c.2.06.27.16.27.22s-.09.16-.25.22a2.76,2.76,0,0,1-.7.06A2,2,0,0,1,68.83,39.74Zm-2-4.93a.41.41,0,0,0-.09.26,12.8,12.8,0,0,0,.06,2s.09.15.16.15a3.45,3.45,0,0,0,1.38-.26,1.11,1.11,0,0,0,.42-.9,1.41,1.41,0,0,0-1.42-1.38h-.08A1,1,0,0,0,66.79,34.81Z" />
      <path d="M75.12,34.29a5.65,5.65,0,0,0,.37,1.12c.29.82,1.19,3,1.45,3.71a1.36,1.36,0,0,0,1,1c.26.1.33.16.33.24a.41.41,0,0,1-.43.26c-.41,0-.86-.07-1.29-.07l-1,0c-.22,0-.34-.11-.34-.21s.09-.21.3-.28.39-.18.35-.43-.33-.93-.37-1a.31.31,0,0,0-.25-.18c-.15,0-.53,0-.89,0a7.23,7.23,0,0,0-.93,0,.35.35,0,0,0-.24.15,4.45,4.45,0,0,0-.33.95c-.07.37,0,.39.33.49s.35.14.35.31-.17.21-.41.21-.23,0-.64,0-.81.07-1.2.07c-.2,0-.42-.06-.42-.21s.14-.21.35-.28a1.4,1.4,0,0,0,1-.84c.47-.88,1.09-2.54,1.72-3.95a4.72,4.72,0,0,0,.18-.53,1.72,1.72,0,0,1,.23-.32l.35-.27a.72.72,0,0,1,.28-.13A.26.26,0,0,1,75.12,34.29Zm-.82,1.38c-.2.43-.59,1.43-.76,1.94,0,0,.1.16.11.16a4.09,4.09,0,0,0,.7,0,5.93,5.93,0,0,0,.73,0s.08-.1.07-.15c-.05-.21-.65-1.83-.7-2S74.31,35.65,74.3,35.67Z" />
      <path d="M84.27,40.61c-.39,0-1.47,0-3.19-.06-.85,0-1.54.05-1.78.05s-.33-.08-.33-.21.19-.25.42-.3c.66-.17.84-.24.87-.68,0-.23,0-.61,0-.93,0-1,0-2.59,0-3.2,0-.34-.28-.41-.64-.53-.21,0-.34-.18-.34-.32s.12-.18.31-.18.78,0,1.18,0c.61,0,1-.1,1.57-.1.22,0,.39.09.39.21s-.13.27-.45.34c-.61.12-.73.26-.76.59s-.06,2.25-.07,3.19c0,.28,0,.55,0,.88a.66.66,0,0,0,.36.48A5.74,5.74,0,0,0,83,40a1,1,0,0,0,.93-.41,6,6,0,0,0,.63-.89c.14-.2.19-.31.29-.31s.26.25.21.42a13.67,13.67,0,0,0-.51,1.65A.57.57,0,0,1,84.27,40.61Z" />
    </svg>
  );
}
