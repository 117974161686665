import React from "react";
export default function DatzMuseum(props: {
  color?: string;
  className?: string;
}) {
  const { color = "#404041", className } = props;
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 152.31 56"
      width={153}
      height={56}
      fill={color}
      className={className}
    >
      <path d="M46.42,39.51a1.45,1.45,0,0,0,.56-1c.09-.7.09-3.27.09-4.26,0-.24-.17-.61-.32-.66s-.44-.18-.7-.27-.5-.27-.5-.46.25-.34.52-.34.66,0,1,0l1.49-.06c.09,0,.24.27.24.5,0,.9,0,5.42,0,6.34,0,.74.29.8.33.8S50,40,50.23,40c.05,0,.17.27.17.35a.56.56,0,0,1-.35.57,16.72,16.72,0,0,0-1.72.3,4.7,4.7,0,0,1-.9.15c-.09,0-.21-.21-.24-.36s0-.7-.06-1c0,0-.11-.15-.19-.09a5.42,5.42,0,0,1-3.27,1.37A2.45,2.45,0,0,1,42,40.6a1.92,1.92,0,0,1-.49-1.21V34.3c0-.19-.19-.61-.8-.94-.23-.12-.44-.27-.44-.48s.57-.35.65-.35c.47,0,1.94-.11,2.08-.11s.21.23.21.67c0,.9,0,3.5,0,5,0,1.13.24,1.36.52,1.62a2.36,2.36,0,0,0,1.11.27A3.31,3.31,0,0,0,46.42,39.51Z" />
      <path d="M55.79,32.45l.67.19a1.22,1.22,0,0,1,.36.42,11,11,0,0,1,0,1.15c0,.15-.17.61-.47.61-.08,0-.27-.19-.47-.57A2,2,0,0,0,54.32,33a1.28,1.28,0,0,0-1.22,1.23c0,1,.9,1.3,2,1.87s2,1.22,2,2.46a2.5,2.5,0,0,1-1.57,2.31,4.37,4.37,0,0,1-1.81.39,3.17,3.17,0,0,1-2.14-.52,3.17,3.17,0,0,1-.42-1.51c0-.55.14-.7.38-.7s.27.15.54.57A2.54,2.54,0,0,0,54,40.48a1.34,1.34,0,0,0,1.47-1.3A1.43,1.43,0,0,0,54.72,38c-.57-.33-1-.57-1.66-.93a2.58,2.58,0,0,1-1.51-2.29,2.34,2.34,0,0,1,1.1-2,4.19,4.19,0,0,1,1.82-.55A4.51,4.51,0,0,1,55.79,32.45Z" />
      <path d="M65.24,33.54A2.71,2.71,0,0,1,65.59,35c0,.24-.35.63-.56.63l-4.52.07a.54.54,0,0,0-.34.23,1.8,1.8,0,0,0-.1.65,3.21,3.21,0,0,0,3.11,3.3h.1a4.3,4.3,0,0,0,2.12-.8c.09-.06.25,0,.25.27a1,1,0,0,1-.27.65,4.2,4.2,0,0,1-3,1.3c-2.63,0-4.12-1.78-4.12-4.34a4.64,4.64,0,0,1,2-4,4.46,4.46,0,0,1,2.46-.71A2.84,2.84,0,0,1,65.24,33.54Zm-4.92,1.2a.23.23,0,0,0,.13.21c.23,0,.93,0,1.53,0,.8,0,1.76,0,1.76-.76S63.24,33,62.13,33A2.31,2.31,0,0,0,60.32,34.74Z" />
      <path d="M72.46,39.51a1.47,1.47,0,0,0,.57-1c.09-.7.09-3.27.09-4.26,0-.24-.17-.61-.32-.66s-.44-.18-.7-.27-.5-.27-.5-.46.25-.34.52-.34.65,0,1,0l1.49-.06c.08,0,.23.27.23.5,0,.9,0,5.42,0,6.34,0,.74.3.8.34.8s.86-.1,1.07-.12a.72.72,0,0,1,.17.35.55.55,0,0,1-.36.57,16.53,16.53,0,0,0-1.71.3,4.67,4.67,0,0,1-.91.15c-.08,0-.21-.21-.23-.36s0-.7-.06-1c0,0-.11-.15-.19-.09a5.45,5.45,0,0,1-3.27,1.37,2.47,2.47,0,0,1-1.66-.72,1.86,1.86,0,0,1-.48-1.21V34.3c0-.19-.19-.61-.8-.94-.23-.12-.44-.27-.44-.48s.56-.35.65-.35c.47,0,1.93-.11,2.08-.11s.21.23.21.67c0,.9,0,3.5,0,5,0,1.13.25,1.36.52,1.62a2.43,2.43,0,0,0,1.12.27A3.28,3.28,0,0,0,72.46,39.51Z" />
      <path d="M77.69,33.08a8.8,8.8,0,0,0,1.62-.82c.15-.1.19-.12.23-.12a.48.48,0,0,1,.34.23v.88a.13.13,0,0,0,.13.13l.08,0A5.69,5.69,0,0,1,83,32.29a2.49,2.49,0,0,1,2.2,1.13.27.27,0,0,0,.27.06,9.29,9.29,0,0,1,1.56-.9,3.28,3.28,0,0,1,1.45-.3c1.84,0,2.54,1.72,2.6,3.17s.15,3.63.15,4.16c0,.36.44.61.8.73s.48.21.48.42-.27.34-.47.34c-.48,0-1.14,0-1.62,0s-1.07,0-1.55,0-.59-.19-.59-.36.1-.31.5-.42a.83.83,0,0,0,.68-.93c0-1,0-2.65,0-3.55a2.62,2.62,0,0,0-.33-1.55,1.73,1.73,0,0,0-1.53-.7,3.51,3.51,0,0,0-1.85.52,1.1,1.1,0,0,0-.21.65c0,3.3,0,4.08.06,4.56a.92.92,0,0,0,.73.9c.42.15.55.27.55.51s-.25.35-.5.35c-.55,0-1.22,0-1.77,0s-1,0-1.49,0-.63-.21-.63-.38.19-.29.53-.42c.57-.21.82-.33.84-1.15l.06-4a2.27,2.27,0,0,0-.31-.94A2,2,0,0,0,82,33.51a3.09,3.09,0,0,0-1.81.57,2.66,2.66,0,0,0-.23.55c0,3.69,0,4.18,0,4.72a1,1,0,0,0,.7.94c.39.2.47.31.47.46s-.21.36-.53.36c-.5,0-1.19-.06-1.82-.06s-.94.1-1.43.1-.59-.17-.59-.35.19-.32.51-.44c.81-.34.9-.51.93-1.17s.08-2.48.08-4.41a1,1,0,0,0-.46-.76c-.38-.29-.47-.38-.47-.44A.75.75,0,0,1,77.69,33.08Z" />
      <path d="M105.22,36.67a4.48,4.48,0,0,1-4.53,4.64A4.8,4.8,0,0,1,97.37,40a4.5,4.5,0,0,1-1.24-3.11,4.57,4.57,0,0,1,1.68-3.49,4.93,4.93,0,0,1,2.94-1.07A4.47,4.47,0,0,1,105.22,36.67ZM99,33.74a4.32,4.32,0,0,0-.86,2.78,4.68,4.68,0,0,0,1.26,3.36,2.67,2.67,0,0,0,1.59.56,2.17,2.17,0,0,0,1.6-.82,4.89,4.89,0,0,0,.56-2.81c0-1.79-.94-3.65-2.72-3.65A2.28,2.28,0,0,0,99,33.74Z" />
      <path d="M106.33,33.67c-.19,0-.36-.1-.36-.3s.27-.44,1.13-.81c.09,0,.19-.24.19-.28a6.94,6.94,0,0,1,.74-2.92,5.58,5.58,0,0,1,1.62-1.8A4.52,4.52,0,0,1,112,26.7a2.36,2.36,0,0,1,1.87.65,1.22,1.22,0,0,1,.14.56.67.67,0,0,1-.1.34,1.22,1.22,0,0,1-.84.59,1.19,1.19,0,0,1-.55-.21,3.25,3.25,0,0,0-1.51-.78,1.61,1.61,0,0,0-1.53,1.24,10,10,0,0,0-.38,2.9c0,.42.06.55.17.55.67,0,2.26-.07,2.41-.07s.3.7.3.89-.24.39-.36.39h-2.41c-.1,0-.19.11-.19.63,0,1.79.06,3,.11,4.62,0,1,.25,1.14,1.34,1.31.36.06.63.27.63.41s-.27.42-.7.42c-.27,0-1-.06-2.23-.06-.23,0-1,.06-1.55.06-.44,0-.67-.14-.67-.37s.21-.3.52-.42c.55-.24.78-.49.8-.86s.06-2,.06-5.37a.35.35,0,0,0-.17-.27Z" />
      <path d="M121.68,33.08a3.11,3.11,0,0,1,.72,1.81c0,.79-.09,1.68-.09,4,0,.7.36,1,.76,1a2.4,2.4,0,0,0,.71-.19c.09,0,.19.17.19.33a2.11,2.11,0,0,1-.19.55,1.87,1.87,0,0,1-1.53.76,1.45,1.45,0,0,1-1.49-1.31s-.17-.12-.21-.1a16.87,16.87,0,0,1-1.62,1,2.16,2.16,0,0,1-1.11.31,2.38,2.38,0,0,1-2-2.47,1.52,1.52,0,0,1,.31-1,1.89,1.89,0,0,1,.84-.61,20.21,20.21,0,0,0,3.57-1,.41.41,0,0,0,.23-.32V35.1a1.46,1.46,0,0,0-.06-.55,2,2,0,0,0-1.85-1.17,1.72,1.72,0,0,0-.93.28,2.1,2.1,0,0,0-.21,1,.66.66,0,0,1-.19.46,1.47,1.47,0,0,1-.61.17,3.26,3.26,0,0,1-.88-.1.53.53,0,0,1-.25-.34c0-.36.6-1,1.51-1.63a5.08,5.08,0,0,1,2.47-.94A2.53,2.53,0,0,1,121.68,33.08Zm-1.15,6.11a2.26,2.26,0,0,0,.17-.63l0-1.34c0-.09-.11-.13-.17-.13a19.42,19.42,0,0,0-2.25.55c-.58.21-.77.44-.77,1a1.4,1.4,0,0,0,1.4,1.31,2.62,2.62,0,0,0,1.6-.72Z" />
      <path d="M125,33.06c.33-.19,1-.48,1.42-.71a1.53,1.53,0,0,1,.61-.21c.09,0,.26.23.26.29l0,1a.13.13,0,0,0,.21.06,3.36,3.36,0,0,1,2.27-1.24c.6,0,1.15.42,1.15.65a2.16,2.16,0,0,1-.84,1.28.51.51,0,0,1-.34.17.83.83,0,0,1-.48-.21,1.43,1.43,0,0,0-.75-.25,1.15,1.15,0,0,0-1,.48,1.09,1.09,0,0,0-.19.49c0,.21,0,3.23,0,4,.07,1,.24,1.2,1.17,1.45.48.13.76.29.76.48s-.28.36-.53.36c-.65,0-1.72-.08-2.33-.08s-1.11.06-1.7.06c-.38,0-.57-.23-.57-.34s.23-.35.48-.44c.82-.29.89-.6.91-1.09,0-.94.06-3.53.06-4.64,0-.13,0-.27-.53-.63-.23-.17-.46-.36-.46-.48A.5.5,0,0,1,125,33.06Z" />
      <path d="M137.45,32.45c.15,0,.25.24.27.36a5,5,0,0,1,0,.67c-.06.23-.25.27-.48.27l-2.37,0s-.15.09-.15.32l-.06,3.82c0,.93.06,1.28.31,1.58a2,2,0,0,0,1.14.46,3.15,3.15,0,0,0,1-.19,3.59,3.59,0,0,1,.47-.12c.08,0,.19.14.19.31s-.17.48-.51.74a3.87,3.87,0,0,1-2.14.65,2.36,2.36,0,0,1-1.84-1,2.78,2.78,0,0,1-.3-1.49c0-1.47.11-2.75.11-4.75,0-.23-.07-.25-.65-.33a.92.92,0,0,1-.63-.61c0-.08.25-.27.44-.44a10.17,10.17,0,0,0,1.51-1.26,1.38,1.38,0,0,1,.84-.55c.08,0,.19.21.19.32s0,.9,0,1,0,.25.17.25S137,32.45,137.45,32.45Z" />
      <path d="M39.78,40.35c-.35-.13-.8-.38-.8-.74,0-.52-.08-2.83-.14-4.16s-.76-3.17-2.61-3.17a3.31,3.31,0,0,0-1.45.3,9.62,9.62,0,0,0-1.55.9.27.27,0,0,1-.27-.07,2.51,2.51,0,0,0-2.21-1.13,5.73,5.73,0,0,0-2.9,1.07.12.12,0,0,1-.18,0,.11.11,0,0,1,0-.08c0-.21,0-.61,0-.84s-.27-.28-.37-.28c-.32,0-1.22.3-2.67.61a.78.78,0,0,0-.47.57c0,.23.17.38.3.36a8.65,8.65,0,0,1,1.14-.13c.17,0,.37.15.41.83,0,0,0,.41,0,1.92,0,1.28-.05,2.33-.08,2.9s-.1.84-.93,1.17c-.32.12-.51.25-.51.44s.24.35.59.35.94-.1,1.43-.1,1.32.06,1.83.06c.31,0,.52-.14.52-.35s-.06-.26-.47-.47-.65-.33-.7-.93,0-1,0-4.73a3,3,0,0,1,.24-.55,3,3,0,0,1,1.8-.56,1.91,1.91,0,0,1,1.62.65,1.94,1.94,0,0,1,.31.93l-.06,4c0,.82-.27.94-.84,1.16-.33.12-.52.25-.52.42s.29.37.63.37,1,0,1.49,0,1.22,0,1.76,0c.25,0,.5-.12.5-.35s-.12-.36-.54-.5a.94.94,0,0,1-.71-.89c-.05-.48-.07-1.26-.07-4.55a1.29,1.29,0,0,1,.21-.66,3.57,3.57,0,0,1,1.85-.52,1.72,1.72,0,0,1,1.53.7,2.61,2.61,0,0,1,.34,1.55c0,.91,0,2.55,0,3.55a.85.85,0,0,1-.67.94c-.4.1-.51.33-.51.42s.26.35.59.35,1.07,0,1.56,0,1.13,0,1.61,0c.19,0,.47-.08.47-.33S40.06,40.45,39.78,40.35Z" />
      <path d="M21.14,20.17c.08,0,.17-.11.17-.23s0-2.69,0-3.13c-.07-.7-.15-.76-1.08-1a.55.55,0,0,1-.4-.44c0-.06.09-.17.45-.27.85-.21,1.71-.38,2.57-.51.21,0,.28.32.28,1.29,0,1.32-.09,4.2-.09,5.9,0,4.22.07,4.57.09,5.18s.21.84.38.84a8.94,8.94,0,0,0,1.16-.12c.13,0,.3.12.3.35a.78.78,0,0,1-.47.57c-1.45.32-2.36.61-2.67.61-.11,0-.36-.13-.38-.27s0-.65-.07-.86c0-.05-.1-.09-.16-.05a6.36,6.36,0,0,1-2.59,1.05,3.89,3.89,0,0,1-4-4.11,4.59,4.59,0,0,1,2.34-4.07,6.13,6.13,0,0,1,2.92-.84A5.49,5.49,0,0,1,21.14,20.17Zm-3.6,1.38a3.62,3.62,0,0,0-1,2.74c0,1.61,1,3.54,2.89,3.54a3.12,3.12,0,0,0,1.64-.52,1.28,1.28,0,0,0,.21-.53c0-1.36,0-2.58,0-3.94a2.54,2.54,0,0,0-.16-.82A2.55,2.55,0,0,0,19,21,2.18,2.18,0,0,0,17.54,21.55Z" />
      <path d="M32,20.86a3.14,3.14,0,0,1,.72,1.81c0,.8-.09,1.68-.09,4,0,.7.36,1,.76,1a2.42,2.42,0,0,0,.7-.19c.08,0,.19.17.19.33a2.55,2.55,0,0,1-.19.55,1.89,1.89,0,0,1-1.51.76,1.47,1.47,0,0,1-1.5-1.31s-.16-.12-.21-.1a15.57,15.57,0,0,1-1.61,1,2.22,2.22,0,0,1-1.12.31,2.37,2.37,0,0,1-2-2.48,1.56,1.56,0,0,1,.31-1A1.89,1.89,0,0,1,27.3,25a19.3,19.3,0,0,0,3.57-1,.39.39,0,0,0,.23-.31v-.8a1.28,1.28,0,0,0-.06-.55,2,2,0,0,0-1.85-1.16,1.63,1.63,0,0,0-.93.27,2.24,2.24,0,0,0-.21,1,.66.66,0,0,1-.19.46,1.47,1.47,0,0,1-.61.17,3.26,3.26,0,0,1-.88-.1.61.61,0,0,1-.25-.34c0-.36.61-1,1.51-1.63a5.1,5.1,0,0,1,2.48-1A2.57,2.57,0,0,1,32,20.86ZM30.85,27a2.24,2.24,0,0,0,.16-.63L31,25c0-.08-.1-.13-.16-.13a21.7,21.7,0,0,0-2.25.55c-.59.21-.78.44-.78,1a1.41,1.41,0,0,0,1.4,1.3A2.65,2.65,0,0,0,30.85,27Z" />
      <path d="M40.78,20.23c.14,0,.25.23.27.36a5,5,0,0,1,0,.67c-.06.23-.25.27-.48.27l-2.38,0s-.15.09-.15.32L38,25.69c0,.93.06,1.28.32,1.58a1.93,1.93,0,0,0,1.13.46,3.21,3.21,0,0,0,1-.19,3.9,3.9,0,0,1,.46-.12c.09,0,.19.14.19.31a1,1,0,0,1-.5.74,3.87,3.87,0,0,1-2.14.65,2.34,2.34,0,0,1-1.85-1,2.81,2.81,0,0,1-.29-1.49c0-1.47.1-2.75.1-4.75,0-.23-.06-.25-.65-.33a1,1,0,0,1-.63-.61c0-.08.25-.27.44-.44A10.13,10.13,0,0,0,37,19.27a1.36,1.36,0,0,1,.84-.54c.08,0,.19.21.19.31s0,.9,0,1,0,.25.17.25S40.29,20.23,40.78,20.23Z" />
      <path d="M49.11,20.23a.27.27,0,0,1,.21.09.45.45,0,0,1,.13.35l-1.14,1.66c-.77,1.17-2.64,4.26-3.15,5.21,0,0,0,.19,0,.23a4,4,0,0,0,2.15.32c.27,0,.9-.21,1.84-1.22.3-.32.55-.55.78-.55s.25.32.19.51c-.15.4-.36,1.16-.57,1.7a.86.86,0,0,1-.35.38c-1.62,0-2.06-.07-3.68-.07-1,0-2.35.11-2.6.11s-.36-.3-.36-.42a6.08,6.08,0,0,1,.75-1.24c.45-.63,2.69-4.28,3.6-5.88a.14.14,0,0,0,0-.2h0a8.55,8.55,0,0,0-2.31-.11c-.52,0-.86.34-1.47,1.07a1,1,0,0,1-.46.36c-.17,0-.27-.21-.27-.36a4.26,4.26,0,0,1,.31-1.07,5,5,0,0,0,.25-.86.63.63,0,0,1,.32-.38c.08,0,.12,0,.21,0a1.4,1.4,0,0,0,1,.38c.23,0,1.16.09,2.35.07C47.81,20.29,48.84,20.23,49.11,20.23Z" />
    </svg>
  );
}
